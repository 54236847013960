<template>
  <ApexTz        :customer="customer" @clearInputs="clearInputs" @submitAndEdit="submitAndEdit" @submitAndNew="submitAndNew" v-if="formChoice==='apex_tz'" />
  <HrCo          :customer="customer" @clearInputs="clearInputs" @submitAndEdit="submitAndEdit" @submitAndNew="submitAndNew" v-else-if="formChoice==='hr_co'" />
  <Urban         :customer="customer" @clearInputs="clearInputs" @submitAndEdit="submitAndEdit" @submitAndNew="submitAndNew" v-else-if="formChoice==='urban'" />
  <DefaultCreate :customer="customer" @clearInputs="clearInputs" @submitAndEdit="submitAndEdit" @submitAndNew="submitAndNew" v-else />
</template>

<script>
  import { v4 as uuidv4 } from 'uuid';
  import fields from '@/mixins/fields';

  import DefaultCreate from '@/views/contacts/partners/Default';
  import ApexTz from '@/views/contacts/partners/ApexTz';
  import HrCo from '@/views/contacts/partners/HrCo';
  import Urban from '@/views/contacts/partners/Urban';

  export default {

    components: { DefaultCreate, ApexTz, HrCo, Urban },

    data: () => ({
      customer: {
        demographic: {},
      },
    }),

    computed: {
      partnerCode() { return this.$store.getters.settings.partnerCode; },
      formChoice() {
        let f = 'default';
        if (['hr_co', 'winwin_zw'].includes(this.partnerCode)) { f = 'hr_co'; }
        if (['urban_zm', 'urban_ke'].includes(this.partnerCode)) { f = 'urban'; }
        if (this.partnerCode=='apex_tz') { f = 'apex_tz'; }
        return f;
      },
      crops() { return this.$store.getters.cropNames; },
      depotId() { return this.$store.getters.settings.fieldAgentDepotId; },
      depotName() { return ((this.$store.getters.settings.depots.filter(e => e.id==this.depotId )[0]) || {}).name },
    },

    methods: {
      submitAndNew() {
        this.save();
        this.customer = {};
      },
      submitAndEdit() {
        let uuid = this.save();
        this.customer = {};
        this.$router.push({ name: 'ShowCustomer', query: { uuid: uuid } });
      },
      save() {

        let customer = this.customer;
        let demographic = customer.demographic || {};
        let depotId = ( customer.addToDepot ? this.depotId : null );
        let depot = {
          id: this.depotId,
          name: this.depotName,
        }

        let newCustomer = {
          uuid: uuidv4(),
          partnerIdentifier: customer.partnerIdentifier,
          msisdn: customer.msisdn,
          email: customer.email,
          firstName: customer.firstName,
          middleName: customer.middleName,
          surname: customer.surname,
          name: `${(customer.firstName ? customer.firstName : '')} ${(customer.surname ? customer.surname : '')}`.trim(),
          savings_group_name: customer.church,
          note: customer.note,
          isAFarmer: true,
          demographic: {
            gender: customer.gender,
            geographicsArray: customer.regions,
            birthdate: customer.demographic.birthdate,
            dependents: customer.demographic.dependents,
            language: customer.demographic.language,
            maritalStatus: customer.demographic.maritalStatus,
          },
          fieldAgent: {
            id: this.$store.getters.settings.fieldAgentId,
            name: this.$store.getters.settings.name,
          }
        }

        if (customer.identity) {
          newCustomer.demographic['identity'] = {
            number: customer.identity.number,
            type: customer.identity.type,
          }
        }

        if (depotId) { newCustomer.depot = depot; }

        if (customer.fieldSize || customer.primaryCrop) {
          let field = this.newField(customer.primaryCrop);
          field.fieldSize = customer.fieldSize || {};
          this.$set(newCustomer, 'fields', [field]);
          if (depotId) { field.depot = depot; }
        }

        if (this.partnerCode=='hr_co' || this.partnerCode=='winwin_zw') { newCustomer.hrContactStatus = 'new'; }

        this.$store.dispatch('upsertCustomer', newCustomer);
        return newCustomer.uuid;

      },
      
      clearInputs() { this.customer = {}; },
    },

    mixins: [fields],

  }
</script>
