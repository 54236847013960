<template>
  <PageHeader :title="$t('title')"  v-if="customer" back save @back="toCustomer" @save="save" :valid='formValid' >

    <v-row no-gutters >

      <v-col cols="12" class="d-flex justify-space-between">
        <div style="width:80%;">
          <v-select
            :items="categories"
            :label="$t('Category')"
            v-model="category"
            solo
            v-if="categories.length>2"
          ></v-select>
        </div>
        <div style="width:20%; text-align: right;" class="font-weight-bold" >
          {{$t('total')}}
          <Currency :value="totalCost" />
        </div>
      </v-col>

      <v-col cols="12">

        <v-data-table dense :headers="headers" :items="table" item-key="item.uuid" :items-per-page="-1" class="w-100" hide-default-footer :search="searchTrigger" :custom-filter="tableFilter" >

          <template v-slot:header.description>{{$t('Description')}}</template>
          <template v-slot:header.price_per_unit>{{$t('Price')}} <small>/ {{$t('unit')}}</small></template>
          <template v-slot:header.quantity>{{$t('Quantity')}}</template>
          <template v-slot:header.cost>{{$t('Cost')}}</template>

          <template v-slot:item.price_per_unit="{ item }">
            <Currency :value="item.price_per_unit" />
            <span class="hidden-xs-only pl-0"><small>/ {{ item.unit }}</small></span>
          </template>

          <template v-slot:item.quantity="{ item }">
            <v-text-field min=0 type='number' :rules="numberRules" style="text-align: right;" v-model="item.quantity" @input="updateCost(item.uuid)" />
          </template>

          <template v-slot:item.cost="{ item }" class="hidden-xs-only">
            <Currency :value="parseInt(item.quantity)*item.price_per_unit" />
          </template>


        </v-data-table>
      </v-col>

      <v-col cols="12 d-flex justify-space-between" class="font-weight-bold">
        <div></div>
        <div>
          {{$t('total')}}
          <Currency :value="totalCost" />
        </div>
      </v-col>
    </v-row>

  </PageHeader>
</template>


<script>
  import { v4 as uuidv4 } from 'uuid';
  import validationRules from '@/mixins/validationRules';
  import PageHeader from '@/components/PageHeader';
  import InputOrderEdit from '@/views/inputOrders/components/InputOrderEdit';
  import Currency from '@/components/Currency';

  export default {

    components: { PageHeader, InputOrderEdit, Currency },

    data: () => ({
      table: [],
      category: undefined
    }),

    computed: {
      customerUuid() { return this.$route.query.uuid; },
      customer() { return this.$store.getters.customer(this.customerUuid); },
      skus() { return this.$store.getters.settings.skus; },
      currency() { return this.$store.getters.currency; },
      totalCost() { return this.table.map( e => (parseFloat(e.quantity) || 0)*parseFloat(e.price_per_unit) ).reduce((a, b) => a + b, 0); },
      formValid() { return (this.totalCost>0); },
      location() { return ((this.$store.getters.geolocation || {}).coords); },
      fieldAgentId() { return this.$store.getters.settings.fieldAgentId; },
      categories() { return ['All Categories'].concat([...new Set(this.skus.map(e => e.category))].filter(e => e).sort()); },

      headers() { return [
        {
          align: 'start',
          sortable: true,
          value: 'description',
        },
        {
          align: 'center',
          sortable: true,
          value: 'price_per_unit',
        },
        {
          align: 'start',
          sortable: false,
          value: 'quantity',
        },
        {
          align: 'end',
          sortable: false,
          value: 'cost',
        },
      ]; },

      search() { return this.$store.getters.search; },
      searchTrigger() { return (this.search || '') + (this.category || '') },
    },

    watch: {
      skus: {
        immediate: true,
        handler(to) {
          if (to) {
            this.table = to.map( e => {e.quantity = 0; e.cost = 0; e.uuid = uuidv4(); return e; } );
          }
        },
      }
    },

    methods: {
      updateCost(uuid) {
        let index = this.table.findIndex( e => e.uuid===uuid );
        let item = this.table[index];
        item.quantity = parseInt(item.quantity);
        item.cost = item.quantity*item.price_per_unit;
        this.$set(this.table, index, item);
      },
      tableFilter(value, searchTrigger, item) {
        let search = this.search || '';
        let category = (this.category || 'All Categories');
        let r = true;
        if (searchTrigger.length>0) {
          if (search.length>0) { r = r && item.description.match(new RegExp(this.search, 'i')); }
          if (category!=='All Categories') { r = r && item.category===category; }
        }
        return r;
      },
      toCustomer() { this.$router.push({ name: 'ShowCustomer', query: { uuid: this.customerUuid } }); },
      save() {
        this.table.filter( e => (e.quantity || 0)>0 ).forEach( e => {

          const order = {
            uuid: e.uuid,
            createdAt: new Date().toISOString(),
            sku: e.code,
            description: e.description,
            price_per_unit: (parseFloat(e.price_per_unit) || 0),
            value: (parseFloat(e.price_per_unit) || 0) * (parseInt(e.quantity) || 0),
            quantity: (parseInt(e.quantity) || 0),
            status: 'placed',
            
            customerUuid: this.customerUuid,
            location: this.location,
            capturedById: this.fieldAgentId,
          }

          this.$store.dispatch('addOrder', order);

        });

        this.toCustomer();
      },
    },
    mixins: [validationRules],

  }
</script>


<i18n>
{
  "en": {
    "title": "New Order",
    "Description": "Description",
    "Price": "Price",
    "unit": "Unit",
    "Quantity": "Quantity",
    "Qost": "Cost",
    "total": "Total",
    "Category": "Category"
  },
  "es": {
    "title": "Nuevo orden",
    "Description": "Descripción",
    "Price": "Precio",
    "unit": "Unidad",
    "Quantity": "Cantidad",
    "Cost": "Costo",
    "total": "Total",
    "Category": "Category"
  },
  "sw": {
    "title": "Demands",
    "Description": "Maelezo",
    "Price": "Bei",
    "unit": "Kitengo",
    "Quantity": "Wingi",
    "Cost": "Gharama",
    "total": "Jumla",
    "Category": "Jamii"
  }
}
</i18n>
